import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp';

import logo from '../img/irregular_engineering.png'

export default class Footer extends React.Component {
  state = {
      email: null,
  }

  _handleChange = (e) => {
      console.log({
          [`${e.target.name}`]: e.target.value,
      });
      this.setState({
          [`${e.target.name}`]: e.target.value,
      });
  }

  _handleSubmit = (e) => {
      e.preventDefault();

      console.log('submit', this.state);

      addToMailchimp(this.state.email, this.state)
          .then(({ msg, result }) => {
              console.log('msg', `${result}: ${msg}`);

              if (result !== 'success') {
                  throw msg;
              }
              alert(msg);
          })
          .catch((err) => {
              console.log('err', err);
              alert(err);
          });
  }

  render() {
    return (
      <footer className="footer">
        <div className="content">
          <hr style={{
            display: 'block',
            borderStyle: 'inset',
            borderWidth: '3px',
          }} />
          <div className="columns">
              <div className="column is-full">
                <img src={logo}
                  alt="The Irregulars"
                  style={{
                                      display: 'flex',
                                      lineHeight: '1',
                                      maxHeight: '500px',
                                      maxWidth: '500px',
                                      justifyContent: 'space-around',
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                    }}
                />
              </div>
              <div className="column is-full" style={{display: 'flex', 
                maxWidth: '100%', maxHeight: '100%', lineHeight: 1, 
                justifyContent: 'space-evenly', alignItems: 'center', 
                flexDirection: 'column',}}>
              </div>
          </div>
        </div>
      </footer>
    )
  }
}
